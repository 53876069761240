import React from 'react';
import Portfolio from '../components/Portfolio';

const Works = () => {
    return(
        <>
        <Portfolio/>
        </>
    );
}

export default Works;